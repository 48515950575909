import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import ASTSeriesImage from "../../components/logicom-images/ASTSeries.js"

const HaltHass = () => (
    <Layout>
      <SEO title="HALT and HASS – Accelerated Stress Test System" />
      <Container>
        <Row><Col><h2>HALT and HASS – Accelerated Stress Test System</h2></Col></Row> 
        <Row>
          <Col>
            <div>
            <p>HALT & HASS, or commonly known as accelerated stress test system, is capable of exposing product defects in a matter of hours or days instead of weeks or months.</p>
            <p>Thermotron’s patented multi-axis <a href="http://thermotron.com/product/vibration/extreme-repetitive-shock-table">Extreme RSL SCI Table</a> and <a href="http://thermotron.com/features/patented-dual-strike-impactors">Patented Dual Strike Impactor</a> design breaks products that couldn’t previously be broken with traditional systems. They can also force more fatigue and damage at lower Grms levels more quickly, using less compressed air.</p>
            <p>The RSL (Repetitive Shock Low Frequency) SCI (Solenoid Controlled Impactor) table operates in the following three independent vibration modes. The soft start feature is integrated during all modes of vibration. It offers accurate control of Grms levels less than 1 without a spike.</p>
            <ol>
              <li>Low Frequency Grms Mode can provide significant energy below 500 Hz.</li>
              <li>High Frequency Grms Mode provides up to 100 Grms levels that can find product failures in less time.</li>
              <li>Synchronized Shock controls all impactors to strike at the same time and can operate at amplitudes exceeding 1,500 Gpk.</li>
            </ol>
            </div>
          </Col>
        </Row>
        <Row className={'row-cols-1 row-cols-md-2'}>
          <Col>
            <ASTSeriesImage/>
          </Col>
          <Col>
          <br/>
          <p>Thermotron’s patent pending <a href="http://thermotron.com/features/extreme-multi-zone-vibration-control"> Multi-Zone Vibration Control</a> offers control and balance uniformity for one, two, or four different table zones. Test different-sized products at the same Grms level and stress same sized products at different Grms levels. This is optional.</p>
          <br/><p>The Extreme AST chamber features product temperature change rates in excess of 70°C/min. with high volume, high velocity airflow configured through adjustable ducts or slot, maximizing thermal stress directly imposed on the product.  Also, an ergonomic control workstation is conveniently integrated into the chamber.</p>
          <br/><p>Each chamber has a fully featured <a href="http://thermotron.com/support/instrumentation/ast-8800-control-system">AST-8800 Control System</a> including ThermAlarm, product temperature control, full vibration analysis package, vibration spectrum analyzer, alarm and abort limits, and many others. Thermotron’s advanced <a href="http://thermotron.com/features/impactor-monitor-system">Impactor Monitor System</a> technology separates us from others in the industry.</p>
          </Col>
        </Row>
        <Row>
          <Col>
          <p>Years of experience in combining ultra high rate temperature cycling chambers with vibration systems back every Extreme AST System we develop – which provides you with highly accurate and effective testing. Thermotron’s patented repetitive shock vibration table and impactor design is able to provide a concise and effective tool that forces failures more quickly than technology in the past.</p>
          <p>Thermotron Extreme AST Systems simulate various accelerated stress test methods such as Highly Accelerated Life Test (HALT), Highly Accelerated Stress Screen (HASS), Step Stress Testing among others. Each type or combination of stress precipitates specific defects that might be missed by traditional testing methods and equipment.</p>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
  
  export default HaltHass
